import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  OutsideClickHandler,
  ExternalLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';
import IconCollection from '../../../../components/IconCollection/IconCollection';
import {
  listingCategories,
  listingSubCategories,
  listingSubCategoriesChild,
} from '../../../../config/configListing';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import stokenIcon from '../../../../assets/StokenIcon.svg';
import StokenIcon from '../../../../components/StokenIcon/StokenIcon';

const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

const SignupLinkForFree = () => {
  return (
    <NamedLink name="SignupPage" className={classNames(css.topbarLink, css.signUpFree)}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signupForFree" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'messages' : 'messages' }}
    >
      <span className={css.topbarLinkLabel}>
        <IconCollection icon="chatIcon" />
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="StripePayoutPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('StripePayoutPage'))}
            name="StripePayoutPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myWalletLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.pastOrdersLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="InboxOfferPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: 'offerRecieved' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.offersLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myAccountLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="RewardsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('RewardsPage'))}
            name="RewardsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myRewardsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="EditListingPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('EditListingPage'))}
            name="EditListingPage"
            params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.sellingLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ManageSubscriptionPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageSubscriptionPage'))}
            name="ManageSubscriptionPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.membershipLink" />
          </NamedLink>
        </MenuItem>

        {/* <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const CatgeoryMenu = ({ handleCategoryDropDown, setIsCategoryOpen, isCategoryOpen, history }) => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  return (
    <OutsideClickHandler onOutsideClick={() => setIsCategoryOpen(false)}>
      <>
        {isCategoryOpen && (
          <div
            onClick={() => {
              handleCategoryDropDown();
            }}
          >
            <IconCollection icon="logoBarIcon2" />
          </div>
        )}
        <div
          className={classNames(
            css.dropdown,
            isCategoryOpen ? css.openDropDown : css.closeDropDown
          )}
        >
          <div className={classNames(css.categoryDropDownItem)}>
            {listingCategories.map(value => {
              return (
                <div key={value.option}>
                  <label className={css.categoryLabel}>{value.label}</label>
                  <div key={value.option}>
                    {listingSubCategories
                      .filter(e => e.parentCollection === value.option)
                      .map(data => {
                        const isExpanded = expandedCategory === data.label;
                        const subCategoriesChild = listingSubCategoriesChild.filter(
                          child => child.parentCategory === data.option
                        );

                        return (
                          <div
                            key={data.option}
                            className={classNames(isExpanded && css.active, css.categoryItems)}
                            onClick={() =>
                              subCategoriesChild?.length > 0
                                ? setExpandedCategory(isExpanded ? null : data.label)
                                : history.push(`/s?refinementList=${data.option}`)
                            }
                          >
                            <span>{data.label}</span>
                            {isExpanded && (
                              <ul className={css.subCategoryDropdown}>
                                {/* Here you can render your subcategories */}
                                {subCategoriesChild.map(childData => (
                                  <li
                                    key={childData.option}
                                    className={css.subCategoryItem}
                                    onClick={() => {
                                      history.push(`/s?refinementList=${childData.option}`);
                                    }}
                                  >
                                    <span>{childData.label}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {!isCategoryOpen && (
          <div
            onClick={() => {
              handleCategoryDropDown();
            }}
          >
            <IconCollection icon="logoBarIcon" />
          </div>
        )}
      </>
    </OutsideClickHandler>
  );
};

const TopbarDesktop = props => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const dispatch = useDispatch();
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    messageCount,
    offerCount,
    orderCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    topbarAlgoliComponent = false,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // useEffect(() => {
  //   const fetchSubscriptions = async () => {
  //     await dispatch(getSubscriptions());
  //   };
  //   fetchSubscriptions();
  // }, []);

  const handleCategoryDropDown = () => {
    if (!isCategoryOpen) {
      setIsCategoryOpen(true);
    } else {
      setIsCategoryOpen(false);
    }
  };

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(
    rootClassName || css.root,
    className,
    history.location.pathname !== '/' && css.otherPageBackground
  );
  const { cartItems } = (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
  const { rewardsWallet = 0, pointsOnHold = 0, spin } =
    currentUser?.attributes?.profile?.publicData || {};

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.cartItemWrappper}>
        <span className={css.cartCount}>
          <span className={css.badge}>
            {(notificationCount || 0) + (messageCount || 0) + (offerCount || 0) + (orderCount || 0)}
          </span>
        </span>
        <IconCollection icon="inboxIcon" />
      </span>
    </NamedLink>
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const categoryMenuMaybe = (
    <CatgeoryMenu
      currentPage={currentPage}
      currentUser={currentUser}
      routeConfiguration={routeConfiguration}
      history={history}
      handleCategoryDropDown={handleCategoryDropDown}
      isCategoryOpen={isCategoryOpen}
      setIsCategoryOpen={setIsCategoryOpen}
    />
  );

  const chatIcon = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const addToCartIcon = authenticatedOnClientSide ? (
    <NamedLink name="CartPage">
      <span className={css.cartItemWrappper}>
        <span className={css.cartCount}>
          <span className={css.badge}>{cartItems?.length > 0 ? cartItems?.length : 0}</span>
        </span>
        <IconCollection icon="addCartIcon" />
      </span>
    </NamedLink>
  ) : (
    <NamedLink name="SignupPage">
      <span className={css.cartItemWrappper}>
        <span className={css.cartCount}>
          <span className={css.badge}>{0}</span>
        </span>
        <IconCollection icon="addCartIcon" />
      </span>
    </NamedLink>
  );

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const signupLinkForFree = isAuthenticatedOrJustHydrated ? null : <SignupLinkForFree />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  return (
    <nav className={classes} id="desktopNavbar">
      {currentPage !== 'AlgoliaSearchPage' && (
        <div className={css.menuBarWarpper}>
          <div className={css.menuBar}>{categoryMenuMaybe}</div>
        </div>
      )}
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        linkToExternalSite={config?.topbar?.logoLink}
      />
      {topbarAlgoliComponent ? (
        topbarAlgoliComponent
      ) : (
        <TopbarSearchForm
          className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={onSearchSubmit}
          initialValues={initialSearchFormValues}
          appConfig={config}
        />
      )}
      <div className={css.linksWrapper}>
        <NamedLink name="RewardsPage" className={css.topbarLink}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="TopbarDesktop.rewards" />
          </span>
        </NamedLink>
        <ExternalLink
          href="https://help.stoado.com/hc/en-us/articles/14367653289756-Stoado-Verify"
          target="_blank"
          className={css.topbarLink}
        >
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="TopbarDesktop.stoadoVerify" />
          </span>
        </ExternalLink>

        <ExternalLink href="https://help.stoado.com/" className={css.topbarLink}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="TopbarDesktop.helpContact" />
          </span>
        </ExternalLink>
        {isAuthenticatedOrJustHydrated && (
          <div className={css.customLink}>
            <CustomLinksMenu
              currentPage={currentPage}
              customLinks={customLinks}
              intl={intl}
              hasClientSideContentReady={
                authenticatedOnClientSide || !isAuthenticatedOrJustHydrated
              }
            />
          </div>
        )}
        {/* {chatIcon} */}
        <NamedLink name="ReferralPage" className={css.referEarnBadge}>
          <FormattedMessage id="TopbarDesktop.referAndEarn" />
        </NamedLink>
        {isAuthenticatedOrJustHydrated && (
          <div className={css.stokenBadge}>
            <img
              src={
                'https://sharetribe-assets.imgix.net/65a4962c-9527-45f9-9268-8c781a47853d/raw/53/a0b4712554df59768d4fabdaffe0c244e2f1b9?auto=format&fit=clip&h=1200&w=1200&s=7132ff245934ffdd27c633caffcf4b17'
              }
            />
            {/* <StokenIcon /> */}
            <span className={css.text}>{rewardsWallet}</span>
          </div>
        )}
        {!isAuthenticatedOrJustHydrated ? null : (
          <NamedLink name="MyWishlistPage">
            <span className={css.wishlistIcon}>
              <IconCollection icon="iconWishList" />
            </span>
          </NamedLink>
        )}
        {inboxLinkMaybe}
        <span className={css.addToCartIcon}> {isAuthenticatedOrJustHydrated && addToCartIcon}</span>
        {profileMenuMaybe}
        {signupLinkForFree}
        {!isAuthenticatedOrJustHydrated && (
          <div className={css.customLink}>
            <CustomLinksMenu
              currentPage={currentPage}
              customLinks={customLinks}
              intl={intl}
              hasClientSideContentReady={
                authenticatedOnClientSide || !isAuthenticatedOrJustHydrated
              }
            />
          </div>
        )}
        {!isAuthenticatedOrJustHydrated && addToCartIcon}
        {/* {signupLinkMaybe}
        {loginLinkMaybe} */}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
